import React from 'react'
import styled from 'styled-components'
import { Svg as BaseSvg } from './Icon'
import { colors, breakpoints, vw } from '../style'
import { ThemeContext } from '../context/ThemeContext'

const Svg = styled(BaseSvg)`
  width: auto;
  height: 38px;
  color: ${colors.dark};

  @media screen and ${breakpoints.medium} {
    width: ${p => (p.width ? vw(p.width) : vw(50))};
    height: ${p => (p.height ? `${p.height}px` : '38px')};
  }
`

export default function Logo({ width, height }) {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 104.1 102.1"
          style={{ color: theme.color }}
          height={height}
        >
          <title>DAZN</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                d="M104.1 27.5H93.9V12.7H83.6V6H73.4V1.7H63.2V0h-53v102.1h53v-1.7h10.2v-4.3h10.2v-6.7h10.3V74.6h10.2V27.5zM4 .8A3.2 3.2 0 11.8 4 3.2 3.2 0 014 .8M4 0a4 4 0 104 4 4 4 0 00-4-4z"
                className="cls-1"
              />
              <path
                d="M3.4 5.8h-.8V2.2h1.5c.8 0 1.3.4 1.3 1.1a1 1 0 01-.6 1l.7 1.5h-.8L4 4.5h-.6zm0-1.9H4a.6.6 0 00.6-.6.6.6 0 00-.6-.5h-.6z"
                className="cls-1"
              />
            </g>
          </g>
        </Svg>
      )}
    </ThemeContext.Consumer>
  )
}
