import React from 'react'

function generateId() {
  return Math.random()
    .toString(32)
    .substr(2, 6)
}

const IdContext = React.createContext(generateId)

export function IdProvider(props) {
  const id = React.useRef(0)
  const genId = React.useCallback(
    prefix => {
      id.current += 1
      return prefix ? `${prefix}-${id.current}` : id.current
    },
    [id],
  )

  return <IdContext.Provider value={genId}>{props.children}</IdContext.Provider>
}

export function useId(prefix) {
  const genId = React.useContext(IdContext)
  const ref = React.useRef(genId(prefix))
  return ref.current
}
