import React from 'react'
import styled from 'styled-components'

const StyledSplitText = styled.span`
  position: relative;
  width: 100%;
  min-height: 100%;

  .char {
    position: relative;
    /* -webkit-text-stroke: 5px #000; */
    /* margin-left: -0.5rem; */
  }
`

const SplitText = props => {
  const { copy, type } = props

  return (
    <StyledSplitText role={props.role}>
      {type === 'words' &&
        copy.split(' ').map((word, idx) => (
          <React.Fragment key={`${type}-${word}-${idx + 1}`}>
            <span
              className="word"
              data-word={word}
              style={{
                '--word-index': idx,
              }}
            >
              {word}
            </span>
            <span aria-hidden="true" key={word} className="whitespace">
              {' '}
            </span>
          </React.Fragment>
        ))}
      {type === 'chars' &&
        copy.split(' ').map((word, idx) => (
          <React.Fragment key={`${type}-${word}-${idx + 1}`}>
            <span
              className="word"
              data-word={word}
              style={{
                '--word-index': idx,
              }}
            >
              {word.split('').map((char, index) => (
                <span
                  key={`${type}-${char}-${index + 1}`}
                  aria-hidden="true"
                  className="char"
                  data-char={char}
                  style={{
                    '--char-index': index,
                  }}
                >
                  {char}
                </span>
              ))}
            </span>

            <span aria-hidden="true" className="whitespace">
              {' '}
            </span>
          </React.Fragment>
        ))}
    </StyledSplitText>
  )
}

export default SplitText
