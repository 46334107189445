import React, { useCallback } from 'react'
import styled from 'styled-components'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from '@reach/router'
import Link from './Link'
import { Text } from './Text'
import StyledLink from './StyledLink'
import SplitText from './SplitText'
import { breakpoints, colors } from '../style'

let selectedLang = null

if (typeof window !== 'undefined' && window.location) {
  if (window.location.pathname.includes('/sv')) {
    selectedLang = 'sv'
  }
}

function setActiveLang(lang) {
  selectedLang = lang
}

export function getActiveLang() {
  if (!selectedLang || selectedLang === 'en') {
    return 'en'
  }
  return selectedLang
}

export function isDefaultLanguage() {
  return !selectedLang || selectedLang === 'en'
}

export function getActiveLangPath() {
  if (!selectedLang || selectedLang === 'en') {
    return ''
  }
  return `/${selectedLang}`
}

export function getUrl(location, country) {
  const langPath = country !== 'en' ? `/${country}` : ''
  const url = location.pathname.replace(`/${selectedLang}`, '')

  return langPath + url
}

const Li = styled.li`
  padding: 0;

  &:first-child {
    margin-left: 0;
    @media ${breakpoints.medium} {
      margin-left: auto;
    }
  }
`

function SelectLanguage({ location, textColor, styleVariant }) {
  return (
    <>
      <Li style={{}}>
        <Link
          onClick={() => {
            setActiveLang('sv')
          }}
          to={getUrl(location, 'sv')}
          textColor={textColor}
          styleVariant={styleVariant}
          variant="large"
        >
          Svenska
        </Link>
      </Li>
      <Li style={{ padding: '0 5px' }}>
        <Text style={{ lineHeight: 'inherit' }} textColor={textColor}>
          /
        </Text>
      </Li>
      <Li>
        <Link
          to={getUrl(location, 'en')}
          onClick={() => {
            setActiveLang('en')
          }}
          textColor={textColor}
          styleVariant={styleVariant}
          variant="large"
        >
          English
        </Link>
      </Li>
    </>
  )
}

export default function SelectLanguageWrapper(props) {
  const cb = useCallback(
    ({ location }) => {
      return <SelectLanguage location={location} {...props} />
    },
    [props],
  )
  return <Location>{cb}</Location>
}
