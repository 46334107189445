import * as React from 'react'
import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { CleanTag } from './CleanTag'
import { fluidRange, breakpoints, breakpointNr } from '../style'

const Link = styled.a`
  font-weight: 400;
  font-size: 1.1em;

  color: ${props => props.textColor};
  display: grid;

  text-decoration: none;

  &:focus {
    outline: none;
    border: none;
  }

  ${props =>
    props.variant === 'medium' &&
    css`
      font-weight: 400;
      font-size: 1.1em;

      @media ${breakpoints.medium} {
        font-size: 1.25em;
      }

      @media ${breakpoints.xlarge} {
        font-size: 1.875em;
      }
    `}

  ${props =>
    props.variant === 'large' &&
    css`
      font-weight: 400;
      font-size: 1.1em;

      @media ${breakpoints.medium} {
        font-size: 1.25em;
      }

      @media ${breakpoints.large} {
        font-size: ${fluidRange({
          min: 20,
          max: 30,
          viewportMin: breakpointNr.large,
          viewportMax: breakpointNr.xlarge,
        })};
      }

      @media ${breakpoints.xlarge} {
        font-size: 1.875em;
      }
    `}

  &:hover {
    & > span {
      transform: translateX(var(--link-translate-x));
      &:nth-child(1) {
        opacity: 0;
        .char {
          transform: rotate3d(1, 0.3, 0, -90deg);
        }
      }
      &:nth-child(2) {
        opacity: 1;
        .char {
          transform: rotate3d(0, 0, 0, 90deg);
        }
      }
    }
  }
  & > span {
    grid-area: 1/1;
    transition: opacity 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95),
      transform 0.4s cubic-bezier(0.445, 0.05, 0.55, 0.95);

    &:nth-child(1) {
      /* font-family: 'Playfair Display'; */
      font-weight: 400;
    }
    &:nth-child(2) {
      /* font-style: italic; */
      /* font-family: 'Roboto'; */
      font-weight: 500;
      opacity: 0;
      .char {
        transform: rotate3d(1, -0.5, 0, 90deg);
      }
    }

    .word {
      white-space: nowrap;
    }
    .char {
      display: inline-block;
      transform-origin: 50% 50% 0.4em;
      transition: transform 0.5s cubic-bezier(0.5, 0, 0, 1);
      transition-delay: calc(0ms + var(--char-index) * 25ms);
      backface-visibility: hidden;
      margin: 0 -0.02em;
    }
  }
`

const RouterLink = props => {
  const { styleVariant, textColor, textActive, ...rest } = props
  return React.createElement(CleanTag, { as: GatsbyLink, ...rest })
}

const StyledLink = React.forwardRef(({ to, ...props }, ref) => {
  if (to) {
    return <Link as={RouterLink} to={to} {...props} ref={ref} />
  }

  return <Link {...props} ref={ref} />
})

export default StyledLink
