import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import Header from './Header'
import SkipToContentLink from './SkipToContentLink'
import { IdProvider } from './IdManager'
import NoSSR from './NoSSR'
import CookieToast from './CookieToast'
import components from './MDXComponents'
import { GlobalStyle } from '../style'
// import Scroll from './Scroll'
import '../lib/iconLibrary'
import '../assets/fonts/circular.css'

export default function App({ children, pageContext }) {
  return (
    <MDXProvider components={components}>
      <IdProvider id="container" data-scroll-container>
        {/* <Scroll callbacks={location} /> */}
        <GlobalStyle />
        <SkipToContentLink />
        <Header pageContext={pageContext} />
        {children}
        <NoSSR>
          <CookieToast />
        </NoSSR>
      </IdProvider>
    </MDXProvider>
  )
}
