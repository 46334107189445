import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Svg = styled.svg`
  display: inline-block;
  vertical-align: -0.078125em;
  height: 0.625em;
  overflow: visible;
  font-size: inherit;
  fill: currentColor;
  pointer-events: none;

  &:not(:root) {
    overflow: visible;
  }
`

export default function Icon(icon, ...rest) {
  return <FontAwesomeIcon icon={icon} {...rest} />
}

Icon.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  size: PropTypes.oneOf(['xs', '2xs', 'sm', 'lg', 'xl', '2xl']),
  fixedWidth: PropTypes.bool,
  listItem: PropTypes.bool,
  rotation: PropTypes.number,
  flip: PropTypes.oneOf(['horizontal', 'vertical', 'both']),
  border: PropTypes.bool,
}
