import React, { useRef, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from '@reach/router'
import {
  useSpring,
  useTransition,
  useChain,
  config,
  animated,
  interpolate,
} from 'react-spring'
import { A } from './Link'
import { Grid, Column } from './Grid'
import SplitText from './SplitText'
import StyledLink from './StyledLink'
import { getActiveLangPath, isDefaultLanguage } from './SelectLanguage'
import useFocusTrap from '../lib/useFocusTrap'
import useDisableScroll from '../lib/useDisableScroll'
import useToggle from '../lib/useToggle'
import {
  colors,
  fluidRange,
  easings,
  durations,
  breakpoints,
  vw,
} from '../style'
import { mainNavigation } from '../routes'
import { ThemeContext } from '../context/ThemeContext'

function getProps({ href, isPartiallyCurrent }) {
  return isPartiallyCurrent && href !== '/'
    ? { 'data-partially-current': true }
    : null
}

const NavLink = styled(GatsbyLink)`
  display: inline-block;
  margin-bottom: ${fluidRange({ min: 8, max: 12 })};
  font-size: ${fluidRange({ min: 36, max: 48 })};
  line-height: 0.798611em;
  font-weight: 400;
  text-decoration: none;
  color: white;
  transition: all ${durations.fast} ${easings.easeOutQuad};
  -webkit-tap-highlight-color: transparent;
  &:last-child {
    margin-right: 0;
  }
  &:hover,
  &.focus-visible,
  &[aria-current],
  &[data-partially-current] {
    outline: none;
    text-indent: ${fluidRange({ min: 24, max: 32 })};
    background-color: rgba(255, 255, 255, 0.2);
  }
`

const NavWrapper = styled.nav`
  ${A} {
    text-decoration: none;
  }
  [data-responsive] {
    @media ${breakpoints.small} {
      display: none;
    }
  }
  [data-desktop] {
    display: none;
    @media ${breakpoints.small} {
      display: flex;
    }
    li {
      padding-right: ${vw(40)};
      &:last-child {
        padding-right: 0;
      }
    }
  }
  [data-cover] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform-origin: top right;
    background-color: ${colors.dark};
  }
  [data-content] {
    position: fixed;
    z-index: 9;
    top: 0;
    right: 0;
    right: var(--scrollbar-width);
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    ul {
      margin: auto 0;
      padding-left: ${fluidRange({ min: 24, max: 32 })};
    }
    li {
      transform-origin: left center;
    }
  }
`

const Burger = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 330ms ease-out;

  ${props =>
    props.isOpen &&
    `
    transform: rotate(-45deg);

    .line-menu {
      background-color: ${props.theme.colorSecondary} !important;
    }
    .line-menu.start {
      transform: rotate(-90deg) translateX(3px);
    }

    .line-menu.end {
      transform: rotate(-90deg) translateX(-3px);
    }
  `}

  .line-menu {
    background-color: ${props => props.theme.color};
    mix-blend-mode: difference;
    border-radius: 5px;
    width: 100%;
    height: 6px;
  }

  .line-menu.half {
    width: 40%;
  }

  .line-menu.start {
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: right;
  }

  .line-menu.end {
    align-self: flex-end;
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: left;
  }
`
const IconWrapper = styled.button`
  position: relative;
  border-radius: 5px;
  width: 40px;
  height: 25px;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  display: inline-flex;
  transform: scale(0.908);
  background: transparent;
  outline: none;
  border: 0;
  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: 0;
    top: -30%;
    width: 100%;
    height: 160%;
    border: 2px solid ${p => p.textColor};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }
`

// This is based off a codepen! Much appreciated to: https://codepen.io/aaroniker/pen/KGpXZo
const MoonOrSun = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: ${p => (p.isDark ? '4px' : '2px')} solid ${p => p.textColor};
  background: ${p => p.textColor};
  transform: scale(${p => (p.isDark ? 0.55 : 1)});
  transition: all 0.45s ease;
  overflow: ${p => (p.isDark ? 'visible' : 'hidden')};

  &::before {
    content: '';
    position: absolute;
    right: -9px;
    top: -9px;
    height: 24px;
    width: 24px;
    border: 2px solid ${p => p.textColor};
    border-radius: 50%;
    transform: translate(${p => (p.isDark ? '14px, -14px' : '0, 0')});
    opacity: ${p => (p.isDark ? 0 : 1)};
    transition: transform 0.45s ease;
  }

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: -4px 0 0 -4px;
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0 -23px 0 ${p => p.textColor}, 0 23px 0 ${p => p.textColor},
      23px 0 0 ${p => p.textColor}, -23px 0 0 ${p => p.textColor},
      15px 15px 0 ${p => p.textColor}, -15px 15px 0 ${p => p.textColor},
      15px -15px 0 ${p => p.textColor}, -15px -15px 0 ${p => p.textColor};
    transform: scale(${p => (p.isDark ? 1 : 0)});
    transition: all 0.35s ease;
  }
`

const MoonMask = styled.div`
  position: absolute;
  right: -1px;
  top: -8px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 0;
  background: ${p => p.background};
  transform: translate(${p => (p.isDark ? '14px, -14px' : '0, 0')});
  opacity: ${p => (p.isDark ? 0 : 1)};
`

const DarkModeToggle = ({ isDark, textColor, background, ...props }) => (
  <IconWrapper isDark={isDark} {...props}>
    <MoonOrSun isDark={isDark} textColor={textColor} background={background} />
    <MoonMask isDark={isDark} textColor={textColor} background={background} />
  </IconWrapper>
)
const NAV_ID = 'navigation'

function Navigation({ location }) {
  const [isOpen, toggle] = useToggle(false)
  const theme = React.useContext(ThemeContext)
  const isDark = theme.theme === `dark`

  const navRef = useRef(null)

  useFocusTrap(navRef, { shouldTrap: isOpen })
  useDisableScroll(isOpen)

  // Close nav on location change
  useEffect(() => {
    toggle(false)
  }, [location, toggle])

  // Update nprogress color
  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.setProperty('--nprogress-color', 'white')

      return () => {
        document.documentElement.style.setProperty('--nprogress-color', null)
      }
    }

    return undefined
  }, [isOpen])

  const closeSpringRef = useRef(null)
  // const closeAnimStyle = useSpring({
  //   ref: closeSpringRef,
  //   from: {
  //     opacity: 0,
  //     scale: 0,
  //   },
  //   to: {
  //     opacity: isOpen ? 1 : 0,
  //     scale: isOpen ? 1 : 0,
  //   },
  // })

  const coverSpringRef = useRef(null)
  const coverAnimStyle = useSpring({
    ref: coverSpringRef,
    from: {
      opacity: 0,
      scaleX: 0,
      scaleY: 0,
      x: 0,
      y: 0,
    },
    to: {
      opacity: isOpen ? 1 : 0,
      scaleY: isOpen ? 1 : 0,
      scaleX: isOpen ? 1 : 0,
      x: isOpen ? 0 : -16,
      y: isOpen ? 0 : 16,
    },
  })

  const itemsTransitionRef = useRef(null)
  const transitions = useTransition(
    isOpen ? mainNavigation : [],
    item => item.id,
    {
      ref: itemsTransitionRef,
      unique: true,
      config: { ...config.default, friction: 30 },
      trail: 250 / mainNavigation.length,
      reverse: !isOpen,
      from: { opacity: 0, x: -40 },
      enter: { opacity: 1, x: 0 },
      leave: { opacity: 0, x: -12 },
    },
  )

  useChain(
    isOpen
      ? [coverSpringRef, itemsTransitionRef, closeSpringRef]
      : [closeSpringRef, itemsTransitionRef, coverSpringRef],
    isOpen ? [0, 0.1, 0.21] : [0, 0, 0.1],
  )

  return (
    <NavWrapper>
      <ul data-desktop>
        {mainNavigation
          .filter(child => child.link !== '/')
          .map(child => (
            <li key={child.id}>
              <StyledLink
                to={`${getActiveLangPath()}/${child.link}`}
                getProps={getProps}
                textColor={theme.color}
                variant="medium"
              >
                <SplitText
                  copy={isDefaultLanguage() ? child.title : child.sv.title}
                  role="heading"
                  type="chars"
                />
                <SplitText
                  copy={isDefaultLanguage() ? child.title : child.sv.title}
                  role="heading"
                  type="chars"
                />
              </StyledLink>
            </li>
          ))}
        <li>
          <DarkModeToggle
            isDark={isDark}
            textColor={theme.color}
            background={theme.background}
            onClick={() => {
              if (theme.theme === 'light') {
                theme.toggleTheme('dark')
              } else {
                theme.toggleTheme('light')
              }
            }}
          />
        </li>
      </ul>

      <div data-responsive>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Burger
            type="button"
            onClick={() => {
              toggle()
            }}
            isOpen={isOpen}
            style={{ marginTop: 4 }}
            aria-expanded={isOpen}
            aria-controls={NAV_ID}
            theme={theme}
          >
            <div className="line-menu half start" />
            <div className="line-menu" />
            <div className="line-menu half end" />
          </Burger>
        </div>

        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <animated.div
          data-content
          id={NAV_ID}
          ref={navRef}
          style={{
            opacity: coverAnimStyle.opacity,
            right: isOpen ? 'var(--scrollbar-width)' : null,
            visibility: coverAnimStyle.opacity.interpolate(o =>
              o === 0 && !isOpen ? 'hidden' : 'visible',
            ),
            pointerEvents: coverAnimStyle.opacity.interpolate(o =>
              o !== 0 && !isOpen ? 'none' : 'auto',
            ),
          }}
          onKeyDown={event => {
            if (event.key === 'Escape') {
              event.stopPropagation()
              toggle(false)
            }
          }}
        >
          <animated.div
            data-cover
            style={{
              opacity: coverAnimStyle.opacity,
              transform: interpolate(
                [
                  coverAnimStyle.x,
                  coverAnimStyle.y,
                  coverAnimStyle.scaleX,
                  coverAnimStyle.scaleY,
                ],
                (x, y, sx, sy) =>
                  `translate3d(${x}px, ${y}px, 0) scale3d(${sx}, ${sy}, 1)`,
              ),
            }}
          />
          <Grid>
            <Column>
              <Burger
                type="button"
                onClick={() => {
                  toggle()
                }}
                isOpen={isOpen}
                style={{
                  zIndex: 20,
                  position: 'relative',
                  margin: '30px 0px 0px auto',
                }}
                aria-label="Close menu"
                theme={theme}
              >
                <div className="line-menu half start" />
                <div className="line-menu" />
                <div className="line-menu half end" />
              </Burger>
            </Column>
          </Grid>
          <Grid my="auto">
            <Column>
              <ul>
                {transitions.map(({ key, item, props: itemStyle }) => (
                  <animated.li
                    key={key}
                    style={{
                      opacity: itemStyle.opacity,
                      transform: itemStyle.x.interpolate(
                        x => `translate3d(${x}%, 0, 0)`,
                      ),
                    }}
                  >
                    <NavLink
                      key={item.link}
                      to={`${getActiveLangPath()}/${item.link}`}
                      getProps={getProps}
                    >
                      {isDefaultLanguage() ? item.title : item.sv.title}
                    </NavLink>
                  </animated.li>
                ))}
              </ul>
            </Column>
          </Grid>
        </animated.div>
      </div>
    </NavWrapper>
  )
}

export default function NavigationWrapper() {
  const cb = useCallback(
    ({ location }) => <Navigation location={location} />,
    [],
  )
  return <Location>{cb}</Location>
}
