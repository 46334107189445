import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { Grid, Column } from './Grid'
import Logo from './Logo'
import { breakpoints, fluidRange, colors } from '../style'
import { getActiveLangPath } from './SelectLanguage'
import Navigation from './Nav'
import { ThemeContext } from '../context/ThemeContext'
import getThemeColor from '../lib/getThemeColor'

export default function Header(props) {
  const { pageContext } = props

  const themeMode = pageContext?.themeStyle?.[0] ?? 'Default'

  const themeColor = getThemeColor(themeMode)

  return (
    <ThemeContext.Consumer>
      {theme => (
        <StyledHeader
          textColor={
            // eslint-disable-next-line no-nested-ternary
            themeMode === 'Default' ? theme.color : themeColor
          }
          headerMode={pageContext.headerMode}
          bg={theme.background}
        >
          <Grid justifyContent="space-between" alignItems="center">
            <Column
              width="auto"
              gutter="none"
              style={{
                zIndex: 13,
              }}
              paddingRight={0}
              paddingLeft={0}
            >
              <LogoLink to={`${getActiveLangPath()}/`}>
                <Logo />
              </LogoLink>
            </Column>
            <Column width="auto" gutter="none" paddingRight={0} paddingLeft={0}>
              <Navigation />
            </Column>
          </Grid>
        </StyledHeader>
      )}
    </ThemeContext.Consumer>
  )
}

const StyledHeader = styled.header`
  background: ${props => props.bg};

  ${props => ({
    color: `${props.textColor} !important` || colors.dark,
  })}

  & nav a,
  & a svg {
    color: ${props => `${props.textColor} !important` || colors.dark};
  }

  ${props =>
    props.headerMode === 'fixed'
      ? `
  background: transparent;
  width: 100%;
  display: flex;
  z-index: 1100;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
  top: 26px;
  left: auto;
  right: 0;
  position: fixed;

  @media screen and ${breakpoints.medium} {
    top: 34px;
  }
`
      : `
padding: ${fluidRange({ min: 24, max: 32 })} 0;
transition: background 0.45s ease;

@media screen and ${breakpoints.small} {
  padding: ${34 / 7.68}vw 0;
}

@media screen and ${breakpoints.medium} {
  padding: 32px 0 22px 0;
}
`}
`

const LogoLink = styled(GatsbyLink)`
  display: block;
`
